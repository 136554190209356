import React from 'react';
import {
  Typography,
  Box,
  Layout,
  Container,
  Section,
  RawHTML,
} from '../components';
import dotBg from '../images/dot-bg.png';
import styled from 'styled-components';
import media from '../styles/mediaQuery';
import { graphql } from 'gatsby';

const HeaderBg = styled.div`
  background-image: url(${dotBg});
  position: absolute;
  width: 80%;
  height: 200px;
  top: 0;
  right: 0;
  z-index: 0;

  ${media.md`
    height: 250px;
  `}
`;

export default function PageTemplate({ data }) {
  const page = data?.wpgraphql?.page ?? {};

  return (
    <Layout title={page.title}>
      <Container mt={{ _: 8, md: 10 }}>
        <Box position="relative" pt={3} pl={{ _: 2, md: 0 }}>
          <Typography
            variant="heading1"
            mt={4}
            style={{ position: 'relative', zIndex: 10 }}
          >
            {page.title}
          </Typography>
          <HeaderBg />
        </Box>
        <Section mt={{ _: '130px', md: '180px' }}>
          <RawHTML html={page.content} />
        </Section>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        content
      }
    }
  }
`;
